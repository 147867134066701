<template>
  <div class="position-relative">
    <div v-if="canEdit" class="edit-icon-settings d-flex align-items-center">
      <feather-icon
        v-if="!isEditable"
        v-b-tooltip.hover.bottom
        icon="Edit2Icon"
        :title="$t('form-create-item.edit')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="openEdit"
      />
      <feather-icon
        v-if="isEditable"
        icon="XIcon"
        size="20"
        class="text-primary zindex-1 mr-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="closeEdit"
      />
      <feather-icon
        v-if="isEditable"
        v-b-tooltip.hover.bottom
        icon="SaveIcon"
        :title="$t('header.action.save-layout')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="handleEditOrder"
      />
    </div>
    <b-card>
      <div v-if="!isPreview" class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t(`collectives.${collectiveFilter}.title`) }}
          </h3>
        </div>
        <b-button
          v-if="canCreate || isStaff"
          class="float-right mr-1"
          variant="outline-primary"
          @click="openModal"
        >
          {{ $t("projects.create") }}
        </b-button>
      </div>
      <div v-if="isLoading" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div>
      <div v-else>
        <div
          v-if="items && items.length > 0 && canList"
          class="d-flex flex-wrap justify-content-around w-100"
        >
          <draggable
            v-if="isEditable"
            v-model="items"
            :list="items"
            :move="checkMove"
            class="w-100"
            @start="dragging = true"
            @end="dragging = false"
          >
            <transition-group class="d-flex flex-wrap justify-content-around w-100">
              <div
                v-for="item in items"
                :key="item.key"
                :class="item.isPublished || (!item.isPublished && canAdd)?'mb-sm-50 x-scroll-element mx-1 mb-3 p-0':'d-none'"
              >
                <course-item
                  v-if="item.isPublished || (!item.isPublished && canAdd)"
                  :item="item"
                />
              </div>
            </transition-group>
          </draggable>

          <div
            v-for="item in items"
            v-show="!isEditable"
            :key="item.key"
            :class="item.isPublished || (!item.isPublished && canAdd)?'mb-sm-50 x-scroll-element mx-1 mb-3 p-0':'d-none'"
          >
            <course-item
              v-if="item.isPublished || (!item.isPublished && canAdd)"
              :item="item"
            />
          </div>
        </div>

        <!-- Container without data: Placeholder -->
        <b-row v-else class="horizontal-placeholder">
          <b-col v-if="placeholder" cols="12">
            <img :src="placeholder">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t("available.message", { itemName: collectiveFilter }) }}
            </p>
          </b-col>
        </b-row>
        <div v-if="isLoadingNextPage" class="mb-5">
          <b-spinner
            type="grow"
            small
            variant="primary"
            class="mx-auto mt-3 d-block"
          />
        </div>
        <b-button
          v-if="hasMore"
          class="center-x my-3"
          variant="outline-primary"
          @click="fetchData(lastLoadedPage + 1)"
        >
          {{ $t("action.load-more") }}
        </b-button>
      </div>

      <!-- Create course -->
      <b-modal
        id="modal-create-courses"
        centered
        hide-footer
        size="lg"
      >
        <template #modal-header>
          <language-selector-header 
            :title="$t('courses.actions.create')" 
            @closeModal="closeModal" 
            @selectLanguage="(language)=>selectedLanguage = language" 
          />
        </template>

        <!-- Loading -->
        <membership-event-create-modal
          item-type="courses"
          :selected="selectedLanguage"
          @close-modal="closeModal"
        />
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import ListContainer from '@core/constants/ListContainer';
import CollectiveFilter from '@/views/apps/collectives/constants/CollectiveFilter';
import CommunitiesPlaceholder from '@/assets/images/placeholders/light/communities.svg';
import { checkPermissions } from '@/@core/utils/roles-utils';
//import { AppIDLUT } from '@copernicsw/community-common';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
//import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import CourseItem from '@/views/apps/courses/components/CourseItem.vue';
import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
import draggable from 'vuedraggable';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';

export default {
  name: 'CoursesList',
  components: {
    CourseItem,
    MembershipEventCreateModal,
    draggable,
    LanguageSelectorHeader,
  },
  props: {
    courses: {
      type: Array,
      required: false,
    },
    community: {
      type: Object,
    },
    collectiveFilter: {
      type: String,
      required: false,
      default: 'courses',
      validator(collectiveFilter) {
        return Object.values(CollectiveFilter).includes(collectiveFilter);
      },
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isContactModalVisible: false,
      isLoading: false,
      isLoadingNextPage: false,
      perPage: 20,
      lastLoadedPage: 1,
      isEditable: false,
      dragging: false,
      actualItems: [],
      isSending: false,
      firstItems: [],
      selectedLanguage: this.currentLocale,
      items: []
    };
  },
  watch: {
    courses() {
      this.items = this.courses;
    },
  },
  computed: {
    appId() {
      return 18;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
    gridContainer() {
      return ListContainer.Grid;
    },
    placeholder() {
      return CommunitiesPlaceholder;
    },
    communities() {
      return this.$store.getters.communitiesOthers['courses']
        ?.unpaginated;
    },
    hasMore() {
      return (
        this.communities?.length
        < this.$store.getters.communitiesOthers['courses']?.meta?.total
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      if(this.community){
        return this.community;
      }
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    canCreate() {
      let collectiveType = 'courses';
      collectiveType = collectiveType.substring(0, collectiveType.length - 1);
      return checkPermissions(
        'create',
        collectiveType,
        this.loggedMemberRoles,
        this.collective,
      );
    },
    canList() {
      let collectiveType = 'courses';
      collectiveType = collectiveType.substring(0, collectiveType.length - 1);

      return (
        this.isStaff
          || checkPermissions(
            'view',
            collectiveType,
            this.loggedMemberRoles,
            this.collective,
          )
      );
    },
    canEdit() {
      let collectiveType = 'courses';
      collectiveType = collectiveType.substring(0, collectiveType.length - 1);
      return checkPermissions(
        'update',
        collectiveType,
        this.loggedMemberRoles,
        this.collective,
      );
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
    this.isLoading = true;
    const coursesLength = this.courses?.length;
    // console.log('courses length:', coursesLength);
    // console.log('is preview?', this.isPreview)
    if(!coursesLength && !this.isPreview){
      // console.log('getting courses...');
      await this.fetchData();
    }else {
      this.items = this.courses;
    }
    this.isLoading = false;
  },
  methods: {
    async fetchData(page = 1) {
      // console.log('Fetching courses...')
      if (this.page !== page) {
        this.isLoadingNextPage = true;
      }
      this.page = page;
      const requestConfig = {
        communityParentSlug: null,
        isCourse: true,
      };
      if(!this.community){
        requestConfig.communityParentSlug = this.$store.getters.currentCollective.slug;
      }
      // console.log('this.community', this.community);
      // console.log('requestConfig', requestConfig);
      const result = await this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: 'courses',
        communitySlug: this.community ? this.community.slug : null,
        page,
        perPage: this.perPage,
        requestConfig,
        forceAPICall: true
      });
      console.log('result', result)
      if(result && result.total){
        this.items = JSON.parse(JSON.stringify(result.data));
      }
      this.updateBreadcrumbs();
      this.isLoading = false;
      this.isLoadingNextPage = false;
      console.log('this.items', this.items);
    },
    openModal() {
      this.$bvModal.show('modal-create-courses');
    },
    closeModal() {
      this.$bvModal.hide('modal-create-courses');
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
            this.appTitle
            || this.$t(`collectives.courses.title`),
          active: true,
        },
      ];
      console.log('breadcrumbs', breadcrumbs);
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    /* Ordenar cursos */
    async checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.communities];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [index, item] of this.actualItems.entries()) {
        item.order = index + 1;
      }
      await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: '/communities/simply/order',
          requestConfig: {
            communities: this.actualItems,
            mainTypeID: 2,
          },
        },
      });
      this.firstItems = [];
      this.firstItems = [...this.actualItems];
      this.isEditable = false;
      this.isSending = false;
    },
    openEdit() {
      this.firstItems = [];
      this.firstItems = [...this.communities];
      this.isEditable = true;
    },
    closeEdit() {
      this.$store.commit('SET_ITEMS', {
        type: 'communitiesOthers',
        storedKey: 'courses',
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isEditable = false;
    },
    /* Fi ordenar cursos */
  },
};
</script>
<style lang="scss" scoped>
.edit-icon-settings {
  position: absolute;
  right: 0px;
  top: -50px;
  cursor: pointer;
}
</style>
