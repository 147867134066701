<template>
  <b-row
    class="px-lg-2 px-1 pb-2"
    style="max-width:1300px; margin:auto; min-height: 100% !important;"
  >
    <b-col lg="6" sm="12" class="px-lg-1 px-0 mt-2 h-auto">
      <!-- TOP LEFT: IMAGE -->
      <b-card
        overlay
        :class="
          isTablet
            ? 'membership-event-image'
            : 'membership-event-image membership-event-image-desktop mb-0 h-100'
        "
        :img-src="getImageSrc(item.bgURL) || getImageSrc(item.logoURL) || Placeholder"
        img-alt="Space Image"
        style="background-color: white"
        :style="isTablet ? 'max-height:300px' : 'height: 100% !important; max-height: 600px !important; min-width:100%' "
      />
    </b-col>

    <!-- TOP RIGHT: CARD INFO -->
    <b-col
      lg="6"
      sm="12"
      class="px-lg-2 px-0 mt-lg-2 mt-1  overflow-hidden"
      :style="isMobile ? '' : 'min-height: 100% !important;'"
    >
      <b-card
        class="d-flex flex-column justify-content-between"
        :class="isMobile ? 'mb-1 p-2' : 'mb-0 px-3 pt-3 pb-3'"
        :style="
          isMobile
            ? 'min-height:350px'
            : 'height: 100% !important; min-height:350px; max-height: 600px !important;'
        "
        no-body
      >
        <!-- Title and attendees -->
        <div>
          <div>
            <p v-if="item.type" class="text-muted font-small-2 mb-0">
              {{ translate(item.type).toUpperCase() }}
            </p>
            <h3
              class="text-dark font-weight-bold mb-0 mt-50"
              style="font-weight: 600 !important"
            >
              {{ translate(item.name) }}
            </h3>
            <p v-if="item.headline" class="mb-0 html-text-ellipsis-2" :class="showModality?'':'mb-2'">
              {{ translate(item.headline) }}
            </p>
          </div>
          <p v-if="showModality" class="my-1 mb-2 text-ellipsis-lines-1">
            <b-icon-camera-video
              v-if="!item.modality || item.modality === 'online'"
              font-scale="1.05"
              class="mr-50"
            />
            <b-icon-geo-alt v-else font-scale="1.05" class="mr-25" />
            <span v-if="item.modality">{{ $t(`community-others.${item.modality}`) }}</span>
            <span v-else>{{ $t("community-others.online") }}</span>
            <span v-if="item.locations && item.locations.length > 0">
              <span class="mx-50">-</span>
              <b-link
                v-for="(location, index) in item.locations"
                :key="index"
                href="#locations"
                class="my-1 mb-2 text-ellipsis-lines-1"
              >
                {{ location.address }}
              </b-link>
            </span>
            <span v-else-if="item.room && item.room.locations">
              <span class="mx-50">-</span>
              <b-link
                href="#rooms"
              >
                {{ item.room.locations.address }}
              </b-link>
            </span>
          </p>
          <h2
            v-if="hasPrice"
            class="my-1"
          >
            <span v-if="item.spaceSettings.maxPrice" class="h4">{{ $t('services.from') }} </span>{{ item.spaceSettings.minPrice.toLocaleString(currentLocale) }}
            {{ item.spaceSettings.minPriceCurrency.ISO != null ? item.spaceSettings.minPriceCurrency.ISO : plans[0].currencySymbol }}
          </h2>
          <p v-if="item.occupation && (item.occupation.online != null || item.occupation['on-site'] != null)" class="mb-25" style="font-size: 0.8rem; font-weight: 600">
            {{ `${$t("community-others.occupation")} / ${$t("community-others.capacity")}`.toUpperCase() }}
          </p>
          <b-row v-if="item.occupation && (item.occupation.online != null || item.occupation['on-site'] != null)" class="mb-1">
            <b-col
              v-if="(item.occupation.online !== null || item.occupation['on-site'] !== null)"
              :cols="item.modality === 'hybrid'?5:12"
            >
              <feather-icon icon="UsersIcon" class="mr-50" />
              {{ getCapacity('online') }} <span v-if="item.modality === 'hybrid'">({{ $t("community-others.online") }})</span>
            </b-col>
            <b-col
              v-if="(item.occupation.online !== null || item.occupation['on-site'] !== null) && item.modality === 'hybrid'"
              :cols="item.modality === 'hybrid'? 5 : 12 "
            >
              <feather-icon icon="UsersIcon" class="mr-50" />
              {{ getCapacity('on-site') }} ({{ $t("community-others.on-site") }})
            </b-col>
          </b-row>
          <b-row v-if="item.startDate ||item.endDate||item.mainType==='Event'">
            <b-col v-if="item.startDate ||(!item.startDate && !item.endDate)" :cols="(!item.startDate && !item.endDate) || item.endDate?6:9" :sm="(!item.startDate && !item.endDate)||item.endDate?5:10">
              <p class="mb-25" style="font-size: 0.8rem; font-weight: 600">
                {{ $t("community-others.start") }}
              </p>
              <p v-if="item.startDate" class="mb-0">
                {{ timestampToTime(item.startDate) }}
              </p>
              <p
                v-if="item.startDate"
                class="mb-0 text-primary"
              >
                {{ timestampToHour(item.startDate) }} h <span v-if="userTimezone !==item.timezone">({{ timezone(item.startDate) }})</span>
              </p>
              <p v-if="!item.startDate && !item.endDate" class="mb-0">
                {{ $t("community-others.coming-soon") }}
              </p>
            </b-col>
            <b-col v-if="item.endDate ||(!item.startDate && !item.endDate)" :cols="(!item.startDate && !item.endDate)||item.startDate?6:9" :sm="(!item.startDate && !item.endDate)||item.startDate?5:10">
              <p class="mb-25" style="font-size: 0.8rem; font-weight: 600">
                {{ $t("community-others.end") }}
              </p>
              <p v-if="item.endDate" class="mb-0">
                {{ timestampToTime(item.endDate) }}
              </p>
              <p
                v-if="item.endDate"
                class="mb-0 text-primary"
              >
                {{ timestampToHour(item.endDate) }} h <span v-if="userTimezone !==item.timezone">({{ timezone(item.startDate) }})</span>
              </p>
              <p v-if="!item.startDate && !item.endDate" class="mb-0">
                {{ $t(`${itemType}.details.coming-soon`) }}
              </p>
            </b-col>
            <b-col
              v-if="item.startDate || item.endDate"
              :class="isMobile ? 'mt-50' : ''"
              :cols="item.startDate && item.endDate ? 12 : 3"
              sm="2"
              class="zindex-1"
            >
              <b-button
                class="px-1 text-center w-100 d-flex justify-content-center mt-50"
                style="border-color: gainsboro !important"
                variant="outline-secondary"
                @click="showSaveCalendar = !showSaveCalendar"
              >
                <b-icon-calendar2-plus
                  class="text-dark edit-icon float-left text-body"
                />
                <p v-if="isMobile && item.startDate && item.endDate" class="ml-75 mb-0 text-dark">
                  {{ $t('community-others.add-to-external-calendar.title') }}
                </p>
              </b-button>

              <div v-if="showSaveCalendar">
                <add-to-calendar
                  :title="translate(item.name)"
                  :location="(item.locations && item.locations[0]) || 'online'"
                  :start="item.startDate? new Date(item.startDate) : new Date(item.createdAt)"
                  :end="new Date(item.endDate)"
                  :details="getCalendarDetails(item)"
                  inline-template
                >
                  <div
                    class="d-flex flex-column bg-white border rounded position-absolute dropdown-calendar pr-1"
                  >
                    <google-calendar
                      id="google-calendar"
                      class="text-dark d-flex align-itmes-center py-50"
                    >
                      <p class="text-dark float-left ml-1 mb-0">
                        Google Calendar
                      </p>
                    </google-calendar>
                    <microsoft-calendar
                      id="microsoft-calendar"
                      class="text-dark d-flex align-itmes-center py-50"
                    >
                      <p class="text-dark float-left ml-1 mb-0">
                        Microsoft Calendar
                      </p>
                    </microsoft-calendar>
                    <office365-calendar
                      id="office365-calendar"
                      class="text-dark d-flex align-itmes-center py-50"
                    >
                      <p class="text-dark float-left ml-1 mb-0">
                        Office365 Calendar
                      </p>
                    </office365-calendar>
                  </div>
                </add-to-calendar>
              </div>
            </b-col>
            <b-col v-if="userTimezone !==item.timezone && item.startDate" cols="12" class="mt-1">
              <b-alert
                show
                variant="primary"
                class="p-25 m-0 d-flex justify-content-center small"
              >
                <p class="mb-0 text-center">
                  {{ $t("community-others.local-time-alert") }} {{ $t(`collectives.items.${item.mainType}`) }}: {{ timestampToDate(item.startDate) }}h <span v-if="item.endDate"> - {{ timestampToDate(item.endDate) }}h </span> ({{ timezoneSpace(item.startDate) }})
                </p>
              </b-alert>
            </b-col>
          </b-row>
        </div>
        <div v-if="item && itemEvent">
          <communities-others-button
            :item="item"
            :item-community="itemEvent"
            :item-type="itemType"
          />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import moment from 'moment';
import CommunitiesOthersButton from '@/@core/components/communities-others/CommunitiesOthersButton.vue';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { DateTime } from 'luxon';

export default {
  name: 'CollectiveDetailCard',
  components: { CommunitiesOthersButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemEvent: {
      type: Object,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isTablet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSaveCalendar: false,
      Placeholder,
    };
  },
  async created() {
    // console.log('this.item', this.item);
    // console.log('this.itemEvent', this.itemEvent);
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    showModality() {
      return ['events', 'courses'].includes(this.itemType);
    },
    hasPrice() {
      return ['services'].includes(this.itemType) && this.item.spaceSettings?.minPrice !== null;
    },
    userTimezone() {
      const localTime = DateTime.now();
      const { zoneName } = localTime;
      return zoneName;
    },
  },
  methods: {
    getURL(item) {
      let url = '';
      if(item.joinURL){
        url = item.joinURL;
      }else {
        url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
          "{subdomain}",
          item.subdomain || "app"
        ).replace("{slug}", item.slug);
      }
      console.log('url', url);
      return url;
    },
    getCalendarDetails(item) {
      const url = this.getURL(item);
      const description = this.translate(item.description);
      console.log('description', description);
      const newDescription = description + '<br>Link: ' + url;
      return newDescription;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    timestampToDate(timestamp) {
      const date = DateTime.fromISO(timestamp, { zone: this.item.timezone });
      return date.toFormat('ff');
    },
    timezoneSpace(timestamp) {
      return DateTime.fromISO(timestamp, { zone: this.item.timezone }).offsetNameShort;
    },
    timestampToHour(timestamp) {
      return moment(timestamp).format('HH:mm');
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    getCapacity(occupationType) {
      if (this.item.modality === 'online') {
        return `${this.item.currentOccupation ? this.item.currentOccupation : 0} / ${this.item.occupation.online}`;
      }
      if (this.item.modality === 'on-site') {
        return `${this.item.currentOccupation ? this.item.currentOccupation : 0} / ${this.item.occupation['on-site']}`;
      }
      if (this.item.modality === 'hybrid') {
        if (occupationType === 'online') {
          return `${this.item.onlineOccupation
            ? this.item.onlineOccupation
            : 0} ${typeof this.item.occupation.online === 'number' ? `/ ${this.item.occupation.online}` : ''}`;
        }
        if (occupationType === 'on-site') {
          return `${this.item.onsiteOccupation
            ? this.item.onsiteOccupation
            : 0} ${typeof this.item.occupation['on-site'] === 'number' ? `/ ${this.item.occupation['on-site']}` : ''}`;
        }
      }
      return `${(this.item.occupation['on-site'] + this.item.occupation.online) - this.item.currentOccupation} / ${(this.item.occupation['on-site'] + this.item.occupation.online)}`;
    },
    timezone(timestamp) {
      return DateTime.fromISO(timestamp).offsetNameShort;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";

.membership-event-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fd;
  > div{
    min-width: 100%;
    min-height: 100%
  }
}
.membership-event-image img {
  border-radius: 0;
}
.membership-event-image-desktop img {
  min-height: 100%
}

</style>
